'use client';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useState } from 'react';
import CircleGradientLock from './icons/CircleGradientLock';
import Lock from './icons/Lock';
import WarningModal from './modals/WarningModal';

type Props = {
	title: string;
	description: string;
	url: string;
	icon?: React.ReactNode;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isOpen?: boolean;
	setIsOpen?: (isOpen: boolean) => void;
	setSelectedBuilder?: (title: string) => void;
	userPlan?: string;
};

const BuilderModalCard = (props: Props) => {
	const {
		title,
		description,
		icon,
		url,
		onClick,
		onMouseEnter,
		onMouseLeave,
		setIsOpen,
		setSelectedBuilder,
		userPlan,
	} = props;
	const [isWarningOpen, setWarningOpen] = useState(false);
	const t = useTranslations('userPanel');
	const isPlanFree = userPlan === 'Free Plan';
	const handleCardClick = (e: React.MouseEvent) => {
		if (isPlanFree) {
			setWarningOpen(true);
			return;
		}

		if (title === 'New Book' || title === 'New Webinar') {
			setIsOpen?.(true);
			setSelectedBuilder?.(title);
			return;
		}

		onClick?.();
	};

	return (
		<>
			{title === 'New Book' || title === 'New Webinar' ? (
				<div
					onClick={handleCardClick}
					className="group w-full cursor-pointer rounded-[10px] border border-[#E5E5E5] bg-white p-5 hover:bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)]"
					onMouseEnter={() => onMouseEnter && onMouseEnter()}
					onMouseLeave={() => onMouseLeave && onMouseLeave()}
				>
					<div className="flex flex-col gap-2.5 md:flex-row">
						<div className="flex size-[50px] shrink-0 items-center justify-center rounded-full bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)] group-hover:bg-[linear-gradient(90deg,#ffffff_0%,#ffffff_33.41%,#ffffff_63.49%,#ffffff_100.01%)]">
							{icon}
						</div>

						<div className="space-y-[5px] font-poppins">
							<h6 className="text-sm font-bold capitalize not-italic leading-[16.8px] text-[#016DEA] group-hover:text-white">
								{title}
							</h6>

							<p className="text-xs font-medium not-italic leading-[16.8px] text-[rgba(0,0,0,0.50)] group-hover:text-white">
								{description}
							</p>
						</div>
					</div>
				</div>
			) : (
				<Link
					onClick={handleCardClick}
					href={!isPlanFree ? url : '#'}
					className="group w-full rounded-[10px] border border-[#E5E5E5] bg-white p-5 hover:bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)]"
					onMouseEnter={() => onMouseEnter && onMouseEnter()}
					onMouseLeave={() => onMouseLeave && onMouseLeave()}
				>
					<div className="flex flex-col gap-2.5 md:flex-row">
						<div className="flex size-[50px] shrink-0 items-center justify-center rounded-full bg-[linear-gradient(90deg,#016DEA_0%,#005FCD_33.41%,#0052B1_63.49%,#003B7E_100.01%)] group-hover:bg-[linear-gradient(90deg,#ffffff_0%,#ffffff_33.41%,#ffffff_63.49%,#ffffff_100.01%)]">
							{icon}
						</div>

						<div className="space-y-[5px] font-poppins">
							<h6 className="text-sm font-bold capitalize not-italic leading-[16.8px] text-[#016DEA] group-hover:text-white">
								{title}
							</h6>

							<p className="text-xs font-medium not-italic leading-[16.8px] text-[rgba(0,0,0,0.50)] group-hover:text-white">
								{description}
							</p>
						</div>
					</div>
				</Link>
			)}

			{isWarningOpen && (
				<WarningModal
					isOpen={isWarningOpen}
					setIsOpen={() => setWarningOpen(false)}
					message={<p className="text-primary-500">{t('myAccount.myPlan.warningModal.exclusiveAction')}</p>}
					sub_message={t('myAccount.myPlan.warningModal.sub_message')}
					heading={t('myAccount.myPlan.warningModal.exclusiveAction')}
					headingIcon={<Lock height={24} width={24} />}
					yesButtonLabel={<Link href="/my-plan">{t('myAccount.myPlan.warningModal.goToMyPlan')}</Link>}
					MainIcon={<CircleGradientLock />}
					onClick={() => {
						setWarningOpen(false);
						setIsOpen?.(false);
					}}
				/>
			)}
		</>
	);
};

export default BuilderModalCard;
