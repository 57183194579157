import { SVGProps } from 'react';

const LiveIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width={60} height={61} viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M14.9648 0.709349C14.1914 1.1781 12.4219 4.28357 11.6836 6.47497C10.8867 8.81872 10.7344 9.83826 10.7344 12.8031C10.7344 15.1 10.7695 15.5922 10.9922 16.6703C11.543 19.225 12.5391 21.5804 13.9219 23.6429C14.7422 24.8617 15.082 25.1078 15.9609 25.1078C17.0508 25.1078 17.6953 24.4633 17.6953 23.35C17.6953 22.764 17.6367 22.6234 17.0977 21.8265C15.2344 19.0609 14.2969 16.0375 14.2969 12.8031C14.2969 9.56872 15.2344 6.54529 17.0977 3.77966C17.6367 2.98279 17.6953 2.84216 17.6953 2.25622C17.6953 1.52966 17.4609 1.08435 16.9219 0.721067C16.4766 0.428099 15.4219 0.428099 14.9648 0.709349Z"
			fill="white"
		/>
		<path
			d="M43.0781 0.721069C42.5391 1.08435 42.3047 1.52966 42.3047 2.25623C42.3047 2.84216 42.3633 2.98279 42.9023 3.77966C44.7656 6.54529 45.7031 9.56873 45.7031 12.8031C45.7031 16.0375 44.7656 19.0609 42.9023 21.8265C42.3633 22.6234 42.3047 22.764 42.3047 23.35C42.3047 24.4633 42.9492 25.1078 44.0391 25.1078C44.9297 25.1078 45.2578 24.8617 46.125 23.5844C47.4727 21.557 48.457 19.1898 49.0078 16.6703C49.2305 15.5922 49.2656 15.1117 49.2656 12.8031C49.2656 9.89685 49.125 8.8656 48.3867 6.65076C47.6367 4.40076 45.8086 1.15466 45.0234 0.697632C44.543 0.428101 43.5234 0.428101 43.0781 0.721069Z"
			fill="white"
		/>
		<path
			d="M21.1875 4.18982C20.7188 4.44763 19.4766 6.17029 18.9141 7.35388C18.6328 7.9281 18.2578 8.87732 18.082 9.46326C17.7773 10.4594 17.7656 10.6469 17.7656 12.8031C17.7656 14.9594 17.7773 15.1469 18.082 16.1429C18.5391 17.6078 19.3125 19.1664 20.168 20.2914C20.9883 21.3812 21.3047 21.5922 22.1602 21.5922C23.2266 21.5922 23.9063 20.9125 23.9063 19.8578C23.9063 19.225 23.7891 18.9437 23.2148 18.2523C21.082 15.6742 20.6953 11.8539 22.2656 8.84216C22.5234 8.34998 22.9453 7.67029 23.2148 7.35388C23.7891 6.66248 23.9063 6.38123 23.9063 5.74841C23.9063 5.1156 23.6133 4.54138 23.1445 4.24841C22.6875 3.96716 21.668 3.93201 21.1875 4.18982Z"
			fill="white"
		/>
		<path
			d="M36.8555 4.24841C36.3633 4.57654 36.0938 5.12732 36.0938 5.84216C36.0938 6.33435 36.1641 6.52185 36.4805 6.94373C36.6797 7.22498 36.9727 7.6117 37.125 7.81091C38.1094 9.13513 38.6836 10.9633 38.6836 12.8031C38.6836 14.6429 38.1094 16.4711 37.125 17.7953C36.9727 17.9945 36.6797 18.3812 36.4805 18.6625C36.1641 19.0726 36.0938 19.2719 36.0938 19.7406C36.0938 20.9242 36.7266 21.5922 37.8398 21.5922C38.6953 21.5922 39.0117 21.3812 39.832 20.3031C40.6875 19.1664 41.4844 17.5726 41.918 16.1429C42.2109 15.1469 42.2344 14.9476 42.2344 12.8031C42.2344 10.6586 42.2109 10.4594 41.918 9.46326C41.2969 7.4242 39.6445 4.63513 38.7891 4.18982C38.2969 3.93201 37.2891 3.96716 36.8555 4.24841Z"
			fill="white"
		/>
		<path
			d="M28.2539 7.75247C26.8477 8.27981 25.8281 9.2056 25.1602 10.565C24.7969 11.315 24.7852 11.397 24.7852 12.8033C24.7852 14.2095 24.7969 14.2915 25.1602 15.0415C25.8398 16.4243 26.8711 17.3501 28.3125 17.854C28.8867 18.0533 29.2148 18.0884 30.2344 18.0533C31.3477 18.0181 31.5469 17.9712 32.2852 17.6079C33.3633 17.0923 34.2891 16.1665 34.8164 15.0884C35.2031 14.2915 35.2148 14.2329 35.2148 12.8033C35.2148 11.3736 35.2031 11.315 34.8164 10.5181C34.2891 9.43997 33.3633 8.51419 32.2852 7.99856C31.5234 7.62356 31.3594 7.58841 30.1758 7.56497C29.1211 7.52981 28.7695 7.56497 28.2539 7.75247ZM30.8672 11.3853C31.3711 11.7251 31.6406 12.2056 31.6406 12.8033C31.6406 13.1197 31.5469 13.4595 31.418 13.6704C31.0781 14.1743 30.5977 14.4439 30 14.4439C29.0625 14.4439 28.3594 13.7408 28.3594 12.8033C28.3594 11.8658 29.0625 11.1626 30 11.1626C30.3164 11.1626 30.6562 11.2564 30.8672 11.3853Z"
			fill="white"
		/>
		<path
			d="M3.52734 29.0923C2.13281 29.5962 1.10156 30.5337 0.433594 31.893L0.0585938 32.6665V44.6782V56.6899L0.433594 57.4634C1.11328 58.8579 2.16797 59.7837 3.58594 60.2641C4.26562 60.4985 4.60547 60.4985 30.2344 60.4751L56.1914 60.4399L57.0117 60.0415C58.0898 59.5141 59.0156 58.5884 59.543 57.5102L59.9414 56.6899V44.6782V32.6665L59.543 31.8462C59.0156 30.768 58.0898 29.8423 57.0117 29.3149L56.1914 28.9165L30.1758 28.893C4.73438 28.8696 4.14844 28.8696 3.52734 29.0923ZM55.5469 32.6899C55.7695 32.8305 56.0625 33.1235 56.1914 33.3345C56.4258 33.7095 56.4258 33.9321 56.4258 44.6782C56.4258 55.4243 56.4258 55.647 56.1914 56.022C56.0625 56.2329 55.7695 56.5259 55.5469 56.6665L55.1367 56.9243H30C5.07422 56.9243 4.86328 56.9243 4.47656 56.6899C4.26562 56.561 3.97266 56.268 3.83203 56.0454L3.57422 55.6352V44.6782V33.7212L3.83203 33.311C4.11328 32.854 4.45312 32.6079 4.98047 32.479C5.17969 32.4321 16.5352 32.4087 30.2344 32.4087L55.1367 32.4321L55.5469 32.6899Z"
			fill="white"
		/>
		<path
			d="M7.92188 36.1118C7.74609 36.229 7.48828 36.4868 7.37109 36.6626C7.16016 36.9907 7.14844 37.354 7.14844 44.6782C7.14844 52.0024 7.16016 52.3657 7.37109 52.6938C7.48828 52.8696 7.74609 53.1274 7.92188 53.2446C8.23828 53.4556 8.54297 53.4673 12.4219 53.4673C16.3008 53.4673 16.6055 53.4556 16.9219 53.2446C17.4609 52.8813 17.6953 52.436 17.6953 51.7095C17.6953 50.9829 17.4609 50.5376 16.9219 50.1743C16.6055 49.9751 16.3242 49.9517 13.6289 49.9517H10.6641V43.4712C10.6641 37.3306 10.6523 36.9907 10.4414 36.6626C10.0781 36.1235 9.63281 35.8892 8.90625 35.8892C8.47266 35.8892 8.15625 35.9595 7.92188 36.1118Z"
			fill="white"
		/>
		<path
			d="M21.9844 36.1118C21.8086 36.229 21.5508 36.4868 21.4336 36.6626C21.2227 36.9907 21.2109 37.354 21.2109 44.6782C21.2109 52.0024 21.2227 52.3657 21.4336 52.6938C21.7969 53.2329 22.2422 53.4673 22.9688 53.4673C23.6953 53.4673 24.1406 53.2329 24.5039 52.6938C24.7148 52.3657 24.7266 52.0024 24.7266 44.6782C24.7266 37.354 24.7148 36.9907 24.5039 36.6626C24.1406 36.1235 23.6953 35.8892 22.9688 35.8892C22.5352 35.8892 22.2188 35.9595 21.9844 36.1118Z"
			fill="white"
		/>
		<path
			d="M29.0156 36.1118C28.5 36.4517 28.2422 36.9321 28.2422 37.5649C28.2422 38.1978 31.6523 52.0259 31.957 52.6235C32.2383 53.1743 32.7773 53.4673 33.5156 53.4673C34.2539 53.4673 34.793 53.1743 35.0742 52.6235C35.3789 52.0259 38.7891 38.1978 38.7891 37.5649C38.7891 36.5337 38.1094 35.8892 37.043 35.8892C36.3164 35.8892 35.7891 36.1587 35.4961 36.6743C35.3906 36.8501 34.9219 38.5728 34.4414 40.4829C33.9727 42.4048 33.5508 43.9751 33.5156 43.9751C33.4805 43.9751 33.0703 42.4048 32.5898 40.4829C32.1211 38.5728 31.6289 36.8384 31.5117 36.6509C31.3945 36.4517 31.1484 36.2056 30.9609 36.0884C30.4805 35.8071 29.4609 35.8188 29.0156 36.1118Z"
			fill="white"
		/>
		<path
			d="M43.0781 36.1118C42.9023 36.229 42.6445 36.4868 42.5273 36.6626C42.3164 36.9907 42.3047 37.354 42.3047 44.6782C42.3047 52.0024 42.3164 52.3657 42.5273 52.6938C42.6445 52.8696 42.9023 53.1274 43.0781 53.2446C43.3945 53.4556 43.6992 53.4673 47.5781 53.4673C51.457 53.4673 51.7617 53.4556 52.0781 53.2446C52.6172 52.8813 52.8516 52.436 52.8516 51.7095C52.8516 50.9829 52.6172 50.5376 52.0781 50.1743C51.7617 49.9751 51.4805 49.9517 48.7852 49.9517H45.8203V48.1938V46.436H48.7852C52.043 46.436 52.125 46.4126 52.6289 45.6626C52.9336 45.2056 52.9336 44.1509 52.6289 43.6938C52.125 42.9438 52.043 42.9204 48.7852 42.9204H45.8203V41.1626V39.4048H48.7852C51.4805 39.4048 51.7617 39.3813 52.0781 39.1821C52.6172 38.8188 52.8516 38.3735 52.8516 37.647C52.8516 36.9204 52.6172 36.4751 52.0781 36.1118C51.7617 35.9009 51.457 35.8892 47.5781 35.8892C43.6992 35.8892 43.3945 35.9009 43.0781 36.1118Z"
			fill="white"
		/>
	</svg>
);
export default LiveIcon;
