type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Channels = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
			<path d="M21.5 2L13.5 9H11.5L3.5 2M2.5 9H22.5V22H2.5V9Z" stroke={color} />
		</svg>
	);
};
export default Channels;
