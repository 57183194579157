type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Podcast = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
			<path
				d="M9.5 10.001C9.5 8.34413 10.8431 7.00098 12.5 7.00098C14.1569 7.00098 15.5 8.34413 15.5 10.001V13.001C15.5 14.6579 14.1569 16.001 12.5 16.001C10.8431 16.001 9.5 14.6579 9.5 13.001V10.001Z"
				stroke={color}
			/>
			<path d="M13.5 10.001H15.5" stroke={color} strokeLinecap="round" />
			<path d="M13.5 13.001H15.5" stroke={color} strokeLinecap="round" />
			<path d="M9.5 10.001H10.5" stroke={color} strokeLinecap="round" />
			<path d="M9.5 13.001H10.5" stroke={color} strokeLinecap="round" />
			<path
				d="M17.4509 8.25091C17.5889 8.64145 18.0174 8.84614 18.408 8.70811C18.7985 8.57008 19.0032 8.14158 18.8651 7.75105L17.4509 8.25091ZM7.24947 14.1548V10.001H5.74947V14.1548H7.24947ZM5.74947 14.1548C5.74947 14.7602 5.25871 15.251 4.65332 15.251V16.751C6.08713 16.751 7.24947 15.5887 7.24947 14.1548H5.74947ZM17.7494 14.1548C17.7494 15.5887 18.9118 16.751 20.3456 16.751V15.251C19.7402 15.251 19.2494 14.7602 19.2494 14.1548H17.7494ZM12.4994 3.25098C8.77154 3.25098 5.74947 6.27305 5.74947 10.001H7.24947C7.24947 7.10148 9.59997 4.75098 12.4994 4.75098V3.25098ZM12.4994 4.75098C14.7842 4.75098 16.7298 6.21082 17.4509 8.25091L18.8651 7.75105C17.9389 5.13038 15.4396 3.25098 12.4994 3.25098V4.75098Z"
				fill="white"
			/>
			<path
				d="M2.5 12.001C2.5 10.8964 3.39543 10.001 4.5 10.001C5.60457 10.001 6.5 10.8964 6.5 12.001V14.001C6.5 15.1056 5.60457 16.001 4.5 16.001C3.39543 16.001 2.5 15.1056 2.5 14.001V12.001Z"
				stroke={color}
			/>
			<path
				d="M18.5 12.001C18.5 10.8964 19.3954 10.001 20.5 10.001C21.6046 10.001 22.5 10.8964 22.5 12.001V14.001C22.5 15.1056 21.6046 16.001 20.5 16.001C19.3954 16.001 18.5 15.1056 18.5 14.001V12.001Z"
				stroke={color}
			/>
			<path d="M12.5 16.001V19.001" stroke={color} strokeLinecap="round" />
		</svg>
	);
};
export default Podcast;
