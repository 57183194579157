type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Posts = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20" fill="none">
			<rect
				x="0.916667"
				y="0.416667"
				width="19.1667"
				height="19.1667"
				rx="1.37821"
				stroke={color}
				strokeWidth="0.833333"
			/>
			<path
				d="M3.0166 16.5446L4.09717 17.7148L5.17775 16.5446"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.0226 5.91309H3.68129C3.18097 5.91309 2.77539 6.35229 2.77539 6.89407V13.7609C2.77539 14.3027 3.18097 14.7419 3.68129 14.7419H10.0226C10.5229 14.7419 10.9285 14.3027 10.9285 13.7609V6.89407C10.9285 6.35229 10.5229 5.91309 10.0226 5.91309Z"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.26385 9.34549C5.63878 9.34549 5.94273 9.01635 5.94273 8.61034C5.94273 8.20432 5.63878 7.87518 5.26385 7.87518C4.88891 7.87518 4.58496 8.20432 4.58496 8.61034C4.58496 9.01635 4.88891 9.34549 5.26385 9.34549Z"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.9221 11.7954L8.65916 9.34491L3.68066 14.736"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<line
				x1="15.36"
				y1="1.96291"
				x2="18.0209"
				y2="1.96291"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
			/>
			<line
				x1="12.3649"
				y1="6.0685"
				x2="17.688"
				y2="6.0685"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
			/>
			<line x1="12.3649" y1="8.23" x2="17.688" y2="8.23" stroke={color} strokeWidth="0.833333" strokeLinecap="round" />
			<line
				x1="12.3649"
				y1="10.3922"
				x2="17.688"
				y2="10.3922"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
			/>
			<line
				x1="12.3649"
				y1="17.2428"
				x2="15.0258"
				y2="17.2428"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
			/>
			<line
				x1="7.37174"
				y1="17.2428"
				x2="10.0326"
				y2="17.2428"
				stroke={color}
				strokeWidth="0.833333"
				strokeLinecap="round"
			/>
			<circle cx="2.4503" cy="1.69364" r="0.410256" fill={color} />
			<circle cx="3.60264" cy="1.69364" r="0.410256" fill={color} />
			<circle cx="4.75791" cy="1.69364" r="0.410256" fill={color} />
			<line x1="0.5" y1="2.92153" x2="13.8333" y2="2.92153" stroke={color} strokeWidth="0.833333" />
		</svg>
	);
};
export default Posts;
