import XMark from '@/components/icons/XMark';
import { Dialog, DialogPanel } from '@headlessui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
	isOpen: boolean;
	heading: string;
	icon?: ReactNode;
	children: ReactNode;
	footer?: ReactNode;
	customClass?: string;
	zIndexClass?: string;
	bodyClass?: string;
	width?: string;
	onClose: () => void;
	canCloseOnBackdropClick?: boolean;
	disableHeight?: boolean;
	isStrict?: boolean;
	customHeaderClass?: string;
	innerBodyClass?: string;
};

const Modal = (props: Props) => {
	const {
		isStrict = false,
		isOpen,
		heading,
		icon,
		children,
		footer,
		onClose,
		customClass,
		width = 'max-w-[730px]',
		zIndexClass = 'z-50',
		bodyClass,
		canCloseOnBackdropClick = true,
		disableHeight = false,
		customHeaderClass,
		innerBodyClass,
	} = props;

	return (
		<Dialog
			className={clsx('focus:outline-none', zIndexClass)}
			open={isOpen}
			onClose={() => (canCloseOnBackdropClick ? onClose() : {})}
		>
			<div className={clsx('fixed inset-0 w-screen overflow-y-auto bg-black/35', zIndexClass)}>
				<div className="flex min-h-full items-center justify-center p-4">
					<div className={`fixed inset-0 flex items-center justify-center`}>
						<DialogPanel
							className={clsx(
								'mx-4 w-full overflow-auto rounded-[15px] bg-white duration-300 ease-out',
								customClass,
								width
							)}
						>
							<div
								className={`flex items-center justify-between ${customHeaderClass ? customHeaderClass : 'bg-feature-gradient'} px-[20px] py-[15px] text-xl font-bold text-white`}
							>
								<div className="flex items-center justify-center gap-2.5">
									<div className="flex-shrink-0">{icon}</div>
									<div className={'leading-[24px]'}>{heading}</div>
								</div>
								<button onClick={() => onClose()}>
									<XMark />
								</button>
							</div>
							<div className={clsx(`${disableHeight ? '' : 'max-h-[calc(100vh-200px)]'} overflow-auto`, bodyClass)}>
								<div className={clsx('p-5', innerBodyClass)}>{children}</div>
							</div>
							{footer && <div className="flex justify-center border-t border-[#E5E5E5] pb-5 pt-[15px]">{footer}</div>}
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default Modal;
