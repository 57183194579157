import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const TrashIconModal = (props: SVGProps<SVGSVGElement>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4"
			stroke="white"
			strokeLinecap="round"
		/>
		<path d="M20.5 6H3.49988" stroke="white" strokeLinecap="round" />
		<path
			d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5"
			stroke="white"
			strokeLinecap="round"
		/>
		<path d="M9.5 11L10 16" stroke="white" strokeLinecap="round" />
		<path d="M14.5 11L14 16" stroke="white" strokeLinecap="round" />
	</svg>
);
export default TrashIconModal;
