import { SVGProps } from 'react';

const CircleGradientLock = (props: SVGProps<SVGSVGElement>) => (
	<svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x={1} y={1} width={48} height={48} rx={24} stroke="url(#paint0_linear_9684_24402)" strokeWidth={2} />
		<path
			d="M32 24H18C16.8954 24 16 24.8954 16 26V33C16 34.1046 16.8954 35 18 35H32C33.1046 35 34 34.1046 34 33V26C34 24.8954 33.1046 24 32 24Z"
			stroke="url(#paint1_linear_9684_24402)"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20 24V20C20 18.6739 20.5268 17.4021 21.4645 16.4645C22.4021 15.5268 23.6739 15 25 15C26.3261 15 27.5979 15.5268 28.5355 16.4645C29.4732 17.4021 30 18.6739 30 20V24"
			stroke="url(#paint2_linear_9684_24402)"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_9684_24402"
				x1={19.5602}
				y1={47.5379}
				x2={60.0003}
				y2={26.4634}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_9684_24402"
				x1={23.0417}
				y1={34.4583}
				x2={33.7597}
				y2={25.3184}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_9684_24402"
				x1={23.912}
				y1={23.5568}
				x2={31.6142}
				y2={19.097}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset={1} stopColor="#FA0101" />
			</linearGradient>
		</defs>
	</svg>
);
export default CircleGradientLock;
