import { SVGProps } from 'react';

export interface IconsType {
	className?: string;
}

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_117_7882)">
			<path
				d="M24.1695 2.15841C21.5328 2.36349 19.1793 3.74044 17.6656 5.97677C17.1968 6.66036 1.4742 33.8674 1.00545 34.8146C-0.146892 37.09 -0.332438 39.5998 0.478108 41.924C1.43514 44.6877 3.76912 46.8557 6.60115 47.6076C7.81209 47.9299 9.40389 47.9592 25.8785 47.9201C43.5933 47.881 42.4996 47.9201 44.0425 47.383C46.2886 46.6017 48.2418 44.8049 49.2476 42.5881C50.2925 40.2736 50.2535 37.1779 49.1402 34.9904C48.7789 34.2971 32.6168 6.3381 32.3238 5.90841C30.5269 3.29123 27.4996 1.89474 24.1695 2.15841ZM26.6695 6.53341C27.3726 6.79708 28.1539 7.35372 28.6226 7.91036C29.0621 8.43771 45.2437 36.426 45.5855 37.2463C46.0543 38.3986 45.9664 39.8146 45.3414 41.1135C44.7945 42.2463 43.3199 43.3889 42.0601 43.6525C41.7086 43.7209 36.484 43.7502 24.6578 43.7307L7.76326 43.7014L7.1285 43.4279C5.8785 42.9006 4.9117 41.885 4.38436 40.5471C4.07186 39.7756 4.06209 38.0861 4.35506 37.3537C4.57967 36.7971 20.3902 9.38497 21.0738 8.35958C21.7379 7.36348 22.6363 6.70919 23.7593 6.39669C24.482 6.19161 25.9566 6.25997 26.6695 6.53341Z"
				fill="url(#paint0_linear_117_7882)"
			/>
			<path
				d="M24.1699 16.2305C23.75 16.3867 23.1641 16.8262 22.8906 17.1973C22.4414 17.8027 22.334 18.3789 22.3926 19.668C22.5781 23.2227 23.0567 31.0352 23.1055 31.2012C23.3301 31.9629 24.1211 32.5195 24.9805 32.5195C25.5859 32.5195 25.9375 32.3926 26.2988 32.0508C26.8848 31.5039 26.8652 31.6602 27.2559 25C27.6465 18.5938 27.6465 18.1543 27.2949 17.4805C27.0801 17.0703 26.5625 16.5723 26.1133 16.3574C25.6641 16.1524 24.5801 16.0742 24.1699 16.2305Z"
				fill="url(#paint1_linear_117_7882)"
			/>
			<path
				d="M24.4624 34.2975C23.0952 34.5514 22.1675 35.9577 22.4604 37.3444C22.8999 39.4538 25.4585 40.1569 26.894 38.5651C27.1382 38.2917 27.3823 37.9108 27.4409 37.7155C27.5776 37.2174 27.5581 36.3581 27.4018 35.9088C27.1186 35.1178 26.3667 34.4538 25.6245 34.3268C25.4096 34.2878 25.1753 34.2487 25.0971 34.2292C25.019 34.2194 24.7358 34.2487 24.4624 34.2975Z"
				fill="url(#paint2_linear_117_7882)"
			/>
		</g>
		<defs>
			<linearGradient
				id="paint0_linear_117_7882"
				x1="19.5505"
				y1="4.38242"
				x2="58.411"
				y2="26.4976"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_117_7882"
				x1="24.4028"
				y1="16.954"
				x2="29.5854"
				y2="17.8079"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_117_7882"
				x1="24.4116"
				y1="34.4837"
				x2="28.5851"
				y2="36.6248"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#016DEA" />
				<stop offset="1" stopColor="#FA0101" />
			</linearGradient>
			<clipPath id="clip0_117_7882">
				<rect width="50" height="50" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default WarningIcon;
