type Props = {
	color?: string;
	width?: number | string;
	height?: number | string;
} & React.SVGProps<SVGSVGElement>;

const Plus = (props: Props) => {
	const { width = 24, height = 24, color = 'white', ...rest } = props;
	return (
		<svg width={width} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
			<path d="M8 3.33594V12.6693" stroke={color} strokeWidth={1.33333} strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.33594 8H12.6693" stroke={color} strokeWidth={1.33333} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};
export default Plus;
