'use client';
import Button from '@/components/Button';
import TrashIconModal from '@/components/icons/modal/TrashIconModal';
import WarningIcon from '@/components/icons/modal/Warning';
import Modal from '@/components/Modal';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
type Props = {
	onClick?: (event?: any) => void;
	headingIcon?: ReactNode;
	MainIcon?: ReactNode;
	cancelButtonLabel?: string;
	yesButtonLabel?: string | ReactNode | undefined;
	message?: string | ReactNode | undefined;
	children?: string | ReactNode;
	sub_message?: string;
	heading?: string;
	isOpen: boolean;
	setIsOpen: (item: boolean) => void;
};
export default function WarningModal(props: Props) {
	const t = useTranslations('common');
	const {
		heading = t('headers.remove'),
		cancelButtonLabel = t('buttons.cancelButton'),
		yesButtonLabel = t('headers.remove'),
		message = t('modals.removeConfirmation'),
		sub_message,
		headingIcon,
		MainIcon,
		isOpen = false,
		setIsOpen,
		children,
	} = props;
	const { onClick } = props;
	const handleOnClick = (event: any) => {
		setIsOpen(false);
		// console.log(' clicked from modal');
		if (onClick) {
			onClick(event);
		}
	};
	return (
		<>
			{children && children}

			<Modal
				heading={heading}
				icon={headingIcon ? headingIcon : <TrashIconModal />}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				footer={
					<div className="flex flex-row gap-[15px]">
						<Button title={cancelButtonLabel} type={'secondary'} onClick={() => setIsOpen(false)} />
						<Button onClick={handleOnClick} title={(yesButtonLabel as string) || ''} />
					</div>
				}
			>
				<div className="flex flex-col items-center justify-center space-y-[10px] p-[15px]">
					{MainIcon ? MainIcon : <WarningIcon />}
					<div className="text-center font-poppins text-[18px] font-bold">{message}</div>
					{sub_message && (
						<div className="text-center font-poppins text-sm font-semibold text-black/50">{sub_message}</div>
					)}
				</div>
			</Modal>
		</>
	);
}
